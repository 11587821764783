<template>
  <div class="container">
    <header>
      <img
        src="@/assets/newImg/arrow_left.png"
        width="18px"
        height="15px"
        @click="$router.go(-1)"
      />
      <div class="header_right" @click="$router.push('/Language')">
        <p class="txt">{{ $t('common').lang }}</p>
        <van-icon class="rotate90" name="play" size="14" color="#fff" />
      </div>
    </header>
    <!-- 页签 -->
    <div class="navTab">
      <div
        class="item"
        @click="activeNav = 0"
        :class="{ activeNav: activeNav === 0 }"
      >
        {{ $t('common').Phone.toUpperCase() }}
      </div>
      <div
        class="item"
        @click="activeNav = 1"
        :class="{ activeNav: activeNav === 1 }"
      >
        {{ $t('common').Email.toUpperCase() }}
      </div>
    </div>
    <!-- 标题 -->
    <div class="title">
      <div class="title_Btn">{{ $t('login').btn.toUpperCase() }}</div>
    </div>
    <!-- 表单 -->
    <div class="content">
      <div class="form">
        <!-- 手机号 -->
        <div v-if="activeNav == 0" class="item">
          <div class="item_lable">{{ $t('common').Phone }}</div>
          <van-field v-model="PhoneNumber" type="tel" placeholder="">
            <template #left-icon>
              <i class="select_btn">
                <VueCountryIntl
                  schema="popover"
                  v-model="PhoneNumberCode"
                  :listZIndex="10000"
                  popoverClass="selectCountry"
                  :searchInputPlaceholder="$t('common').PleaseSerach"
                  @onChange="selectCountry"
                >
                  <div slot="reference" @click="selectCountry">
                    +{{ PhoneNumberCode }}
                  </div>
                </VueCountryIntl>
              </i>
            </template>
          </van-field>
        </div>
        <!-- 邮箱 -->
        <div v-if="activeNav == 1" class="item">
          <div class="item_lable">{{ $t('common').Email }}</div>
          <van-field v-model="Email" placeholder=""></van-field>
        </div>
        <!-- 密码 -->
        <div class="item">
          <div class="item_lable">{{ $t('common').Password }}</div>
          <van-field v-model="Password" type="password" placeholder="">
          </van-field>
        </div>
        <!-- 登录 -->
        <div class="item_btn" @click="handleLogin">
          {{ $t('login').btn.toUpperCase() }}
        </div>
        <!-- 没有账号 -->
        <div class="c-c-btm">
          <div class="loginTxt" @click="$router.push({ name: 'Register' })">
            <div class="agree">
              {{ $t('login').noAccount }}？<span>{{
                $t('login').toRegister
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import userApi from '@/api/user'
import { Token } from '@/utils/mm'
import { mapGetters } from 'vuex'
import { dataURLtoBlob, getRandomString, getLang } from '@/utils/validate'

export default {
  data() {
    return {
      showImg: false,
      PhoneNumberCode: '60', //手机区号
      PhoneNumber: '', //手机号
      Email: '', //邮箱号
      inputCode: '', //验证码
      imageCode: '', //图形验证码
      imgCode: '', //图形验证码图片
      imgCodeToken: '', //图形验证码图片Token
      Password: '',
      tips: this.$t('register').send,
      countDownSec: 60,
      activeNav: 0,
      timer: null,
      imageCodeTxt: '', //弹窗输入文字
      imageCodeTc: '', //弹窗的图片
      imgCodeTokenTc: '', //弹窗的token
      CaptchaToken: '', //短信验证码
      PrivacyList: [],
      privacyId: null
    }
  },
  computed: {
    ...mapGetters(['basicConfig'])
  },
  mounted() {
    // this.getCaptchaImg()
  },
  methods: {
    selectCountry(val) {
      this.$nextTick(() => {
        const searchInput = document.querySelector(
          '.selectCountry .search-input'
        )
        if (searchInput) {
          searchInput.style.width = '90%'
          searchInput.style.height = 'auto'
        }
      })
    },
    async getCaptchaImg() {
      // 获取图形验证码
      const res = await userApi.getCaptchaImg()
      this.imgCodeToken = res.Data.Token
      var base64Img = res.Data.Data
      var blob = dataURLtoBlob(base64Img)
      const imgUrl = URL.createObjectURL(blob)
      this.imgCode = imgUrl
    },
    //登录
    async handleLogin() {
      if (!this.Password) {
        this.$toast(this.$t('common').input)
        return
      }
      if (!this.PhoneNumber && !this.Email) {
        this.$toast(this.$t('common').input)
        return
      }
      const form = {
        Password: this.Password
      }
      if (this.activeNav == 0) {
        this.$set(form, 'PhoneNumber', this.PhoneNumber)
        this.$set(form, 'Type', 'PhoneNumber')
      }
      if (this.activeNav == 1) {
        this.$set(form, 'Email', this.Email)
        this.$set(form, 'Type', 'Email')
      }
      let res = await userApi.getAccountRefresh(form)
      if (res) {
        this.loginSuccess(res.Data.Token)
      }
    },
    //获取刷新token
    async loginSuccess(token) {
      this.$toast(this.$t('common').success)
      let res = await userApi.getAccountToken(token)
      Token.set(res.Data.Token)
      setTimeout(() => {
        this.getAccountBase()
      }, 1000)
    },
    //获取用户信息
    async getAccountBase() {
      const aBase = await userApi.getAccountBase()
      localStorage.setItem('userInfo', JSON.stringify(aBase.Data))
      this.$router.push({ name: 'Home' })
    },
    goXY() {
      this.$router.push({
        name: 'MsgDetail',
        query: {
          contentId: this.privacyId
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  min-height: 100vh;
  width: 100%;
  background: #ffffff;
  header {
    color: #fff;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    height: 291px;
    background-image: url(../../assets/newImg/regedit_bg.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 20px 20px 0 20px;
    .header_right {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 500;
      position: absolute;
      right: 20px;
      .txt {
        margin: 0 10px;
      }
      .img {
        margin-left: 3px;
        margin-bottom: 2px;
      }
    }
  }
  .navTab {
    display: flex;
    justify-content: space-between;
    margin: -256px 15px 15px 15px;
    height: 45px;
    background-image: url(../../assets/newImg/regedit_tab.png);
    background-size: 100% 100%;
    .item {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50%;
      font-family: Microsoft YaHei;
      font-weight: bold;
      font-size: 9px;
      color: #ffffff;
      margin: 7px 30px 10px 30px;
    }
    .activeNav {
      color: #9a5300;
      background-image: url(../../assets/newImg/active_btn.png);
      background-size: 100% 100%;
    }
  }

  .title {
    display: flex;
    align-items: center;
    justify-content: center;
    .title_Btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 180px;
      height: 40px;
      background: linear-gradient(0deg, #ff9002 0%, #fcb81d 100%);
      box-shadow: 0px 1px 3px 0px rgba(173, 61, 0, 0.75);
      border-radius: 20px;
      font-family: PingFang SC;
      font-weight: 800;
      font-size: 18px;
      color: #ffffff;
      line-height: 12px;
    }
  }

  .content {
    margin: -20px 20px 0 20px;
    .form {
      background: #ffffff;
      box-shadow: 0px 2px 12px 0px rgba(73, 47, 182, 0.75);
      border-radius: 15px;
      padding: 40px 20px;
      .item_lable {
        font-family: Microsoft YaHei;
        font-weight: bold;
        font-size: 12px;
        color: #333333;
        margin-bottom: 10px;
      }
      .item_btn {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 30px;
        height: 44px;
        background: linear-gradient(-90deg, #492fb6 0%, #7f49c5 100%);
        border-radius: 22px;
        font-family: PingFang SC;
        font-weight: 800;
        font-size: 18px;
        color: #ffffff;
      }
      .select_btn {
        margin-right: 10px;
      }
      .send_btn {
        margin-right: -13px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60px;
        height: 24px;
        background: linear-gradient(-90deg, #492fb6 0%, #7f49c5 100%);
        border-radius: 12px;
        font-family: PingFang SC;
        font-weight: bold;
        font-size: 12px;
        color: #ffffff;
      }
      .van-button {
        height: 37px;
        margin-bottom: 10px;
        margin-top: 20px;
      }
    }
  }

  .c-c-a {
    display: flex;
    align-items: center;
  }

  ::v-deep .vue-country-intl .country-intl-input-wrap {
    position: relative;
    border: none;
    border-radius: 0px;
    width: 80px;
    height: 24px;
  }

  ::v-deep .vue-country-intl .country-intl-input {
    width: 0px;
    padding: 0px;
    border-radius: 0px;
    background-color: #23262f;
  }

  ::v-deep .vue-country-intl {
    width: 80px;
    position: relative;
    background-color: #23262f;
    pointer-events: auto;
  }

  ::v-deep .vue-country-intl .country-intl-label {
    display: flex;
    padding: 0px;
    height: 24px;
    // width: 70%;
    align-items: center;
    justify-content: left;
    color: #515151;
    background-color: #23262f;
  }

  ::v-deep .vue-country-list-wrap {
    position: inherit;
    margin-top: 0px;
    width: 305px;
    border: 1px solid #3e536b;
    background-color: #23262f;
  }

  ::v-deep .vue-country-item {
    color: #515151;
  }

  ::v-deep .van-field__value {
    overflow: visible;
    display: grid;
  }

  ::v-deep .van-button__content {
    color: #ffffff;
    font-size: 16px;
    font-weight: 400;
  }

  .van-cell {
    margin-bottom: 20px;
    height: 38px;
    border-radius: 19px;
    border: 0.5px solid #999999;
    display: flex;
    align-items: center;
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 15px;
    color: #333333;
  }

  ::v-deep .van-field__right-icon {
    margin: -10px 8px;
    padding: 0px;
    line-height: normal;
  }

  .imgCodeBox {
    display: flex;
    align-items: center;
    width: 75px;
    height: 32px;
  }

  .loginTxt {
    display: flex;
    align-items: center;
  }

  .agree {
    display: flex;
    align-items: center;

    span {
      margin-left: 5px;
      color: #4a30b7;
    }
  }

  .c-c-btm {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-top: 15px;
    justify-content: center;
  }

  .van-checkbox {
    margin: 15px 0;
    font-size: 12px;
  }

  ::v-deep .van-checkbox__icon--checked .van-icon {
    color: #fff;
    background-color: #2868d5;
    border-color: #2868d5;
  }

  .login_Title {
    margin: 15px 0px 41px;
    font-size: 29px;
    font-weight: 500;
    color: #515151;
  }
}

// 弹窗
.dialogBox2 {
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.dialogBox {
  position: relative;
  width: 300px;
  height: 150px;
  background: #ffffff;
  position: fixed;
  top: 200px;
  left: 40px;
  right: 40px;
  border-radius: 5px;
  font-size: 12px;
  color: #2868d5;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  // flex-direction: column;
}

.dialogBox1 {
  display: none;
}

.chooseInput {
  padding: 0 10px;
  width: 300px;
  height: 120px;
  font-size: 16px;
  line-height: 30px;
  color: #515151;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.chooseBtn {
  width: 300px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  position: absolute;
  bottom: 0;
  background: #2868d5;
  color: #ffffff;
  border-radius: 0px 0px 5px 5px;
}

.rotate90 {
  transform: rotate(90deg);
}
</style>
